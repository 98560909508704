import Vue from 'vue';
import Vuex from 'vuex';
import constellation from "@/services/constellation";
import constellationStore from "@/store/constellationStore";

Vue.use(Vuex);

export default new Vuex.Store({

    state: {
        blackholeImage: null,
        tenants: [],
        currentConstellation: "",
        currentDeviceSelected: "None",
        devices: [],
        devicesNotPlaced: [],
        devicesPlaced: [],
        devicesToBeSubmitted: [],
        deviceType: "",
        editDeviceBool: false,
        image: null,
        imageNumber: 0,
        floorPlanDescription: '',
        floorPlanId: "",
        floorPlanName: '',
        floorPlans: [],
        iconSize: 0,
        pictureWindowHeight: 800,
        uploadedBool: false,
        uploadedData: ""
    },

    mutations: {
        GET_CONSTELLATIONS(state) {
            state.tenants = [];
            constellationStore.dispatch('getConstellations').then(tenants => {
                tenants.forEach(function (constellation) {
                    state.tenants.push({
                        value: {
                            url: constellation.url + ":" + constellation.port,
                            _id: constellation._id,
                            name: constellation.constellationname
                        },
                        text: constellation.constellationname
                    });
                    state.tenants.sort(function (a, b) {
                        return a.text.localeCompare(b.text)
                    });
                }.bind(this));
            });
            state.tenants.sort(function (a, b) {
                return a.text.localeCompare(b.text)
            });
        },
        GET_DEVICES(state) {
            state.devicesNotPlaced = [];
            state.devicesPlaced = [];

            // get constellation device list,
            //         updateDevices(devices) {
            //             if (!this.openEditInfo) {
            //                 devices.map(device => {
            //                     if (this.currentDevice.getDeviceId() === device.getDeviceId()) {
            //                         this.currentDevice.getArchiveStreams().forEach(stream1 => {
            //                             device.getArchiveStreams().forEach(stream2 => {
            //                                 if (stream1.dataChannel === stream2.dataChannel) {
            //                                     if (stream1.isRunning !== stream2.isRunning) {
            //                                         let index = this.recordingUpdate.findIndex(dataChannel => {
            //                                             return dataChannel === stream1.dataChannel
            //                                         })
            //                                         if (index !== -1) {
            //                                             this.recordingUpdate.splice(index, 1);
            //                                         }
            //                                     }
            //                                 }
            //                             });
            //                         });
            //                         this.currentDevice.updateDevice(device.getDevice());
            //                     }
            //                     let update = this.devices.findIndex(_ => _.getDeviceId() === device.getDeviceId());
            //                     if (update !== -1) {
            //                         this.devices[update].updateDevice(device.getDevice());
            //                     } else {
            //                         this.devices.push(device);
            //                     }
            //                 });
            //                 if (Object.keys(this.currentDevice.getDevice()).length !== 0) {
            //                     this.fillSystemSettings();
            //                 }
            //             }
            //         },
            if (state.currentConstellation !== "") {
                constellation.readDeviceList(state.currentConstellation.url).then(response => {
                    response.data.forEach(device => {
                        state.devicesNotPlaced.push({
                            //this is dated and doesn't even use ssDevice
                            name: device.devicename,
                            type: device.devicetype,
                            ipaddress: device.ipAddress,
                            id: device._id
                        });
                    });
                });
            }
        },
        INCREMENT_IMAGE_NUMBER(state) {
            state.imageNumber++;
        },
        RESET_VARIABLES(state) {
            state.blackholeImage = null;
            state.tenants = [];
            // state.currentConstellation = "";
            state.currentDeviceSelected = "None";
            state.devices = [];
            state.devicesNotPlaced = [];
            state.devicesPlaced = [];
            state.devicesToBeSubmitted = [];
            state.deviceType = "";
            state.editDeviceBool = false;
            state.image = null;
            state.imageNumber = 0;
            state.floorPlanDescription = '';
            state.floorPlanId = "";
            state.floorPlanName = '';
            state.floorPlans = [];
            state.iconSize = 0;
            state.pictureWindowHeight = 800;
            state.uploadedBool = false;
            state.uploadedData = "";
        },
        SET_BLACKHOLE_IMAGE(state, image) {
            state.blackholeImage = image;
        },
        SET_CURRENT_CONSTELLATION(state, constellation) {
            state.currentConstellation = constellation;
        },
        SET_CURRENT_DEVICE_SELECTED(state, device) {
            state.currentDeviceSelected = device;
        },
        SET_DEVICES(state, devices) {
            state.devices = devices;
        },
        SET_DEVICE_TYPE(state, type) {
            state.deviceType = type;
        },
        SET_DEVICES_NOT_PLACED(state, devices) {
            state.devicesNotPlaced = devices;
        },
        SET_DEVICES_PLACED(state, devices) {
            state.devicesPlaced = devices;
        },
        SET_DEVICES_TO_BE_SUBMITTED(state) {
            state.devicesToBeSubmitted = [];
            for (let i = 0; i < state.devicesPlaced.length; i++) {
                state.devicesToBeSubmitted.push({
                    deviceId: state.devicesPlaced[i].id,
                    coords: {
                        xCoord: state.devicesPlaced[i].xCoord,
                        yCoord: state.devicesPlaced[i].yCoord
                    },
                    comment: "",
                    actions: [],
                    angle: state.devicesPlaced[i].angle
                });
            }
        },
        SET_EDIT_DEVICE_BOOL(state, bool) {
            state.editDeviceBool = bool;
        },
        SET_FLOOR_PLAN_DESCRIPTION(state, description) {
            state.floorPlanDescription = description;
        },
        SET_FLOOR_PLAN_ID(state, id) {
            state.floorPlanId = id;
        },
        SET_FLOOR_PLAN_NAME(state, name) {
            state.floorPlanName = name;
        },
        SET_FLOOR_PLANS(state, floorPlans) {
            state.floorPlans = floorPlans;
        },
        SET_ICON_SIZE(state, payload) {
            state.iconSize = Math.ceil(Math.min(payload.height, payload.width) / 40);
        },
        SET_IMAGE(state, image) {
            state.image = image;
        },
        SET_PICTURE_WINDOW_HEIGHT(state, height) {
            if (height > state.pictureWindowHeight) {
                state.pictureWindowHeight = height;
            }
        },
        SET_UPLOADED_BOOL(state, bool) {
            state.uploadedBool = bool;
        },
        SET_UPLOADED_DATA(state, data) {
            state.uploadedData = data;
        }
    },

    actions: {
        getConstellations({ commit }) {
            commit('GET_CONSTELLATIONS');
        },
        getDevices({ commit }) {
            commit('GET_DEVICES');
        },
        incrementImageNumber({ commit }) {
            commit('INCREMENT_IMAGE_NUMBER');
        },
        resetVariables({ commit }) {
            commit('RESET_VARIABLES');
        },
        setBlackholeImage({ commit }, image) {
            commit('SET_BLACKHOLE_IMAGE', image);
        },
        setCurrentConstellation({ commit }, constellation) {
            commit('SET_CURRENT_CONSTELLATION', constellation);
        },
        setCurrentDeviceSelected({ commit }, device) {
            commit('SET_CURRENT_DEVICE_SELECTED', device);
        },
        setDevices({ commit }, devices) {
            commit('SET_DEVICES', devices);
        },
        setDevicesToBeSubmitted({ commit }) {
            commit('SET_DEVICES_TO_BE_SUBMITTED');
        },
        setDeviceType({ commit }, type) {
            commit('SET_DEVICE_TYPE', type);
        },
        setEditDeviceBool({ commit }, bool) {
            commit('SET_EDIT_DEVICE_BOOL', bool);
        },
        setFloorPlanId({ commit }, id) {
            commit('SET_FLOOR_PLAN_ID', id);
        },
        setFloorPlans({ commit }, floorPlans) {
            commit('SET_FLOOR_PLANS', floorPlans);
        },
        setIconSize({ commit }, payload) {
            commit('SET_ICON_SIZE', payload);
        },
        setImage({ commit }, image) {
            commit('SET_IMAGE', image);
        },
        setPictureWindowHeight({ commit }, height) {
            commit('SET_PICTURE_WINDOW_HEIGHT', height);
        },
        setUploadedBool({ commit }, bool) {
            commit('SET_UPLOADED_BOOL', bool);
        },
        setUploadedData({ commit }, data) {
            commit('SET_UPLOADED_DATA', data);
        }
    },

    getters: {
        getBlackholeImage: (state) => () => {
            return state.blackholeImage;
        },
        getConstellations: (state) => () => {
            return state.tenants;
        },
        getCurrentConstellation: (state) => () => {
            return state.currentConstellation;
        },
        getCurrentDeviceSelected: (state) => () => {
            return state.currentDeviceSelected;
        },
        getDeviceImage: (state) => () => {
            // switch statement to determine which icon image to use based on device type
            let img;
            switch (state.deviceType) {
                case "accesscontrol":
                    img = require("../../public/img/accesscontrol.jpg");
                    break;
                case "accesspoint":
                    img = "https://upload.wikimedia.org/wikipedia/en/thumb/b/b5/Wireless-icon.png/1125px-Wireless-icon.png";
                    break;
                case "camera":
                    img = require("../../public/img/camera.png");
                    break;
                default:
                    img = "https://pngimg.com/uploads/gps/gps_PNG74.png";
            }
            return img;
        },
        getDevices: (state) => () => {
            return state.devices;
        },
        getDevicesNotPlaced: (state) => () => {
            return state.devicesNotPlaced;
        },
        getDevicesPlaced: (state) => () => {
            return state.devicesPlaced;
        },
        getDevicesToBeSubmitted: (state) => () => {
            return state.devicesToBeSubmitted;
        },
        getEditDeviceBool: (state) => () => {
            return state.editDeviceBool;
        },
        getFloorPlanDescription: (state) => () => {
            return state.floorPlanDescription;
        },
        getFloorPlanId: (state) => () => {
            return state.floorPlanId;
        },
        getFloorPlanName: (state) => () => {
            return state.floorPlanName;
        },
        getFloorPlans: (state) => () => {
            return state.floorPlans;
        },
        getIconSize: (state) => () => {
            return state.iconSize;
        },
        getImage: (state) => () => {
            return state.image;
        },
        getImageNumber: (state) => () => {
            return state.imageNumber;
        },
        getPictureWindowHeight: (state) => () => {
            return state.pictureWindowHeight;
        },
        getUploadedBool: (state) => () => {
            return state.uploadedBool;
        },
        getUploadedData: (state) => () => {
            return state.uploadedData;
        }
    }
});
